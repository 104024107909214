import { useState, useEffect } from "react";

import './Courses.css';

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";

import { SvgAddNewData, SvgArchive, SvgCancel, SvgDelete, SvgEdit, SvgLink, SvgSearch, SvgSetaDown, SvgSetaUp, SvgUser, SvgUserDisabled } from "components/SvgFile";

import { Reg_AltPosition } from "services/RegisterData";

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

export default function Page_Courses(props){

    const [ search, setSearch ] = useState('');
    const [ userId, setUserId ] = useState(GetUserData('id'));

    const [ dataPage, setDataPage ] = useState(GetDataPage('courses'));
    const [ showData, setShowData ] = useState(InitialData(1));
    const [ disabled, setDisabled ] = useState(InitialData(0));

    const [ status, setStatus ]       = useState(0);
    const [ showPage, setShowPage ]   = useState(0);
    const [ itensPage, setItensPage ] = useState(300);

    const page         = Math.ceil(showData.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showData.slice(startItens, endItens);

    function InitialData(value){
        const newData = [];
        dataPage.map((elem, index)=>{
            if(elem.status == value){                
                newData.push(elem);
            }
        });
        return newData;
    }

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('courses').forEach(item =>{
                if(item.status == 1){
                    if(item.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.text.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowData(duplicate);
        }else {
            setShowData(InitialData(1));
        }
        setSearch(value);
    }

    function PageClick(idPage){
        SetListPag('currentPage', 'courses_details')
        SetListPag('currentPageId', idPage);
    }

    function DeleteData(type, id, value){
        SetModalData('Confirmation', { "origin": "courses", "type" : type, "id" : id, "name": value, "setLoading": props.setLoading });
        SetModalState('Confirmation', true);
    }

    function ChangePosition(id, order, type){
        Reg_AltPosition('courses', id, order, type, ()=>{ }, ()=>{ CallbackError() });
    }

    function CallbackError(){
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function NewHistoric(){      
        SetModalData("Historic", { "origin": "courses", "type_register": "show_courses", "type": "Cursos desativados", "data": disabled, "setLoading": props.setLoading });
        SetModalState('Historic', true);
    }

    function CopyLink(value){
        navigator.clipboard.writeText("https://crazypixel.com.br/" + value);
        setStatus(2);
        setTimeout(() => {
            setStatus(1);
        }, 1500);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('courses', setDataPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('courses', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('courses'));
        setShowData(InitialData(1));
        setDisabled(InitialData(0));
    }, [dataPage]);

    return(
        <div className="Page_Courses">
            
            <div className={ status == 2 ? "copy_link" : status == 0 ? "copy_none" : "copy_link copy_hide" }>
                <div className="div_text">
                    Link copiado com sucesso!
                </div>
            </div>

            <div className="new_block">
                <div className="input_search">
                    <SvgSearch color="#111827" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="pesquisar..." />
                </div>
                <div className="title_page">Cursos</div>
                <div className="list_opt_alt_page">
                    {
                        disabled.length == 0 ? null :
                        <>
                            <div data-tooltip-id="historic_" data-tooltip-content="Clique aqui para visualizar os cursos desativados" data-tooltip-place="top" className="" onClick={ ()=>{ NewHistoric(); } }>
                                <SvgArchive color="#606875" className="icons" />
                            </div>
                            <Tooltip id="historic_" />
                        </>
                    } 
                    <div className="div_add_new_data" onClick={ ()=>{ PageClick(0) } }>
                        <div className="new_data_add">
                            <SvgAddNewData color="#ffffff" className="icons" />
                        </div>
                        <div className="name_btn_add">curso</div>
                    </div>
                </div>
            </div>
            <div className="list_data_page">
                <table width="100%" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Título</th>
                            <th width="60">Order</th>
                            <th width="60" align="right">#</th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            currentItens.map((key, index)=>{                                
                                let statusCourse = '';
                                if(key.status === 0){
                                    statusCourse = 'hide_data';
                                }
                                
                                return(
                                    <tr key={ index } className={ statusCourse }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>
                                            <div className="show_img">
                                                <div className="">
                                                    <img alt="img fundo" src={ key.file != "" ? "./assets/courses/img/" + key.file : "./assets/not_file.jpg" } className="img_examplo" />
                                                </div>
                                                <div className="">{ key.title }</div>
                                            </div>
                                        </td>
                                        <td align="right">
                                            {
                                                key.status === 0 ? null :
                                                <div className="div_opt_alt">
                                                    {
                                                        index == 0 ?
                                                        <div className="space_div_icon" /> :
                                                        <div data-tooltip-id={ "position_" + key.id } data-tooltip-content="Alterar posição" data-tooltip-place="top" className="space_div_icon" onClick={ ()=>{ ChangePosition(key.id, key.order_, 'up') } }>
                                                            <SvgSetaUp color="#324d6b" className="icons"/>
                                                        </div>
                                                    }
                                                    {
                                                        (index + 1) == currentItens.length ?
                                                        <div className="space_div_icon" /> :
                                                        <div data-tooltip-id={ "position_" + key.id } data-tooltip-content="Alterar posição" data-tooltip-place="top" className="space_div_icon" onClick={ ()=>{ ChangePosition(key.id, key.order_, 'down') } }>
                                                            <SvgSetaDown color="#324d6b" className="icons"/>
                                                        </div>
                                                    }
                                                    <Tooltip id={ "position_" + key.id } />
                                                </div>
                                            }
                                        </td>
                                        <td align="right">
                                            <div className="div_opt_alt">
                                                <div data-tooltip-id={ "link_" + key.title_url } data-tooltip-content="Gerar link do curso" data-tooltip-place="top" onClick={ ()=>{ CopyLink(key.title_url) } }>
                                                    <SvgLink color="#324d6b" className="icons" />
                                                </div>
                                                <Tooltip id={ "link_" + key.title_url } />
                                                <div data-tooltip-id={ "edit_" + key.id } data-tooltip-content="Desativar curso" data-tooltip-place="top" onClick={ ()=>{ DeleteData('hide_courses', key.id, key.title) } }>
                                                    <SvgCancel color="#F00000" className="icons"/>
                                                </div>
                                                <Tooltip id={ "remuve_" + key.id } />
                                                <div data-tooltip-id={ "del_" + key.id } data-tooltip-content="Deletar curso" data-tooltip-place="top" onClick={ ()=>{ DeleteData('delete_course', key.id, key.title) } }>
                                                    <SvgDelete color="#F00000" className="icons"/>
                                                </div>
                                                <Tooltip id={ "del_" + key.id } />
                                                <div data-tooltip-id={ "edit_" + key.id } data-tooltip-content="Editar curso" data-tooltip-place="top" onClick={ ()=>{ PageClick(key.id) } }>
                                                    <SvgEdit color={ key.status === 0 ? "#ffffff" : "#324d6b" } className="icons"/>
                                                </div>
                                                <Tooltip id={ "edit_" + key.id } />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {
                    showData.length >= 30 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}